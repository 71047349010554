import React from "react"
import Layout from "../../Components/pageComponents/contentContainer"
// import Hero from "../../Components/ssp/hero"
import { Container } from "reactstrap"

import { useStaticQuery, graphql } from "gatsby"
// import Img from "gatsby-image"
import ITService from "../../Components/pageComponents/itService"

// import directory from "../../content/directory.json"

export default function ItInfrastructureServices() {
  const items = [
    {
      name: "Cleaning and Maintenance"
    },
    {
      name: "Diagnostic and Repair"
    },
    {
      name: "Hardware / Software Upgrade"
    },
    {
      name: "OS and Software Installation"
    },
    {
      name: "System Optimization / PC Tune Up"
    },
    {
      name: "Virus and Spyware Removal"
    },
    {
      name: "On-Site / Pull-Out / Carry-In"
    }
  ]
  const set1 = items.slice(0, 2)
  const set2 = items.slice(2, 5)
  const set3 = items.slice(5)

  const Image = useStaticQuery(graphql`
    query {
      cleaning: file(relativePath: {eq: "services/it infrastructure/Diagnostic and Repair Stock.png"}) {
        childImageSharp {
          fixed(width: 350) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      hardware: file(relativePath: {eq: "services/it infrastructure/Upgrade + Installation + Optimization Stock.png"}) {
        childImageSharp {
          fixed(width: 350) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      virus: file(relativePath: {eq: "services/it infrastructure/On-Site + Virus Stock.png"}) {
        childImageSharp {
          fixed(width: 350) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      banner: file(relativePath: {eq: "services/engineer/Engineering Professional Services.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Layout image={Image.banner.childImageSharp.fluid}
      title="It Infrastructure Services">

      <Container
        fluid
        // className="pt-5 pb-5 pl-5 pr-5"
        style={{
          background: `rgb(243, 243, 243)`,
          paddingTop: `2rem`,
          paddingBottom: `3rem`,
        }}
      >
        <h1 className="mx-auto mb-4 heading1 p-3 text-center">
          <span>Services we offer</span>
        </h1>

        <div
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `center`,
            flexDirection: `row`,
          }}
        >
          <ITService img={Image.cleaning.childImageSharp.fixed} info={set1} />
          <ITService img={Image.hardware.childImageSharp.fixed} info={set2} />
          <ITService img={Image.virus.childImageSharp.fixed} info={set3} />
        </div>
      </Container>
    </Layout>
  )
}
