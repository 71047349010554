import React from "react"
import Img from "gatsby-image"
import style from "./styles.module.css"

const ITService = ({ img, info }) => {
  //   console.log(info)
  const items = info.map(({ name }, index) => {
    return <h4>{name}</h4>
  })
  return (
    <div className={style.itemContainer}>
      <div className={style.itemCard}>
        <div>
          <Img fixed={img} />
        </div>
        <div className={style.itemContent}>{items}</div>
        {/* <p>{info}</p> */}
      </div>
    </div>
  )
}

export default ITService
